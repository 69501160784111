import { Injectable, inject } from '@angular/core';
import { Observable, OperatorFunction, map, shareReplay } from 'rxjs';

import { filterNilValue } from '@epi/utils';

import { AuthenticationService } from '../../../authentication/gateways/authentication.service';
import { UserEntity } from '../../../profile/models/user';
import { UserRoles } from '../models/user-role';

@Injectable({ providedIn: 'root' })
export class UserRoleValidator {
  #authenticationService = inject(AuthenticationService);

  #user$: Observable<UserEntity> = this.#authenticationService.user$.pipe(shareReplay(1), filterNilValue());

  get userRole$() {
    return this.#user$.pipe(map((user) => user.roles[0]));
  }

  isAdmin$ = this.#user$.pipe(filterNilValue(), validateRole('ADMIN'));

  isOwner$ = this.#user$.pipe(filterNilValue(), validateRole('OWNER'));

  isEditor$ = this.#user$.pipe(filterNilValue(), validateRole('EDITOR'));
}

function validateRole(role: UserRoles): OperatorFunction<UserEntity, boolean> {
  return function <T extends UserEntity>(source: Observable<T>): Observable<boolean> {
    return source.pipe(map((value) => value.roles.includes(role)));
  };
}
