import type { RoutesWithBreadcrumbs } from '../app.routes';
import { CONTENT_ROUTES } from '../content/content.routes';
import { SEMINARS_ROUTES } from '../seminars/ui/seminars.routes';
import { SETTINGS_ROUTES } from '../settings/settings.routes';
import { STUDENTS_ROUTES } from '../students/ui/students.routes';
import { permissionsResolver } from './permissions.resolver';

export const ADMIN_ROUTES: RoutesWithBreadcrumbs = [
  {
    path: '',
    loadComponent: () => import('./admin.component').then((c) => c.AdminComponent),
    resolve: {
      role: permissionsResolver,
    },
    children: [
      /**
       * TODO: Retirer pour la gestion d'un dashboard d'accueil
       */
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'content',
      },
      {
        path: 'content',
        children: CONTENT_ROUTES,
      },
      {
        path: 'seminars',
        children: SEMINARS_ROUTES,
      },
      {
        path: 'students',
        children: STUDENTS_ROUTES,
      },
      {
        path: 'settings',
        children: SETTINGS_ROUTES,
      },
      {
        path: 'medias',
        loadComponent: () => import('../medias/ui/medias.component').then((c) => c.MediasComponent),
        data: {
          breadcrumbs: [{ label: 'Accueil', routerLink: '/' }, { label: 'Medias' }],
        },
      },
      {
        path: 'profile',
        loadComponent: () => import('../profile/profile.component').then((c) => c.ProfileComponent),
        data: {
          breadcrumbs: [{ label: 'Accueil', routerLink: '/' }, { label: 'Profil' }],
        },
      },
    ],
  },
];
