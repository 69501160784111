import { inject } from '@angular/core';
import type { ResolveFn } from '@angular/router';
import { Observable, switchMap, take, tap } from 'rxjs';

import { PermissionsGateway } from '../settings/roles/gateways/permissions.gateway';
import { PermissionsStore } from '../settings/roles/gateways/permissions.store';
import type { Role } from '../settings/roles/models/user-role';
import { UserRoleValidator } from '../settings/roles/services/user-role.validator';

export const permissionsResolver: ResolveFn<Role> = (): Observable<Role> => {
  const permissionsGateway = inject(PermissionsGateway);
  const userRoleValidator = inject(UserRoleValidator);
  const permissionsStore = inject(PermissionsStore);

  return userRoleValidator.userRole$.pipe(
    take(1),
    switchMap((userRole) => permissionsGateway.getRole(userRole)),
    tap((role) => {
      permissionsStore.setPermissions(role.permissions);
    })
  );
};
