import { registerLocaleData } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { Router, provideRouter, withComponentInputBinding } from '@angular/router';
import * as Sentry from '@sentry/angular';

import { environment } from '@epi/environments';

import { appRoutes } from './app.routes';
import { FIREBASE_CONFIG } from './firebase.config';
import { DATE_PROVIDER, RealDateProvider } from './helpers/date.provider';
import { RealUidProvider, UID_PROVIDER } from './helpers/uid.provider';
import { PermissionsGateway } from './settings/roles/gateways/permissions.gateway';

registerLocaleData(localeFr, 'fr');

if (environment.sentry) {
  Sentry.init({
    dsn: (environment.sentry as { dsn: string }).dsn,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom([
      provideFirebaseApp(() => initializeApp(FIREBASE_CONFIG)),
      provideAuth(() => getAuth()),
      provideFirestore(() => getFirestore()),
      provideStorage(() => getStorage()),
      provideFunctions(() => getFunctions(getApp(), 'europe-west1')),
    ]),
    provideAnimationsAsync(),
    provideHttpClient(),
    provideRouter(appRoutes, withComponentInputBinding()),
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR',
    },
    { provide: UID_PROVIDER, useClass: RealUidProvider },
    { provide: DATE_PROVIDER, useClass: RealDateProvider },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (permissionsGateway: PermissionsGateway) => () => permissionsGateway.initializeRoles(),
      deps: [PermissionsGateway],
      multi: true,
    },
  ],
};
