import { FirebaseOptions } from '@angular/fire/app';

import { environment } from '@epi/environments';

const { apiKey, authDomain, projectId, storageBucket, messagingSenderId, measurementId, appId } = environment.firebase.backOffice;

export const FIREBASE_CONFIG: Readonly<FirebaseOptions> = {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
} as const;
