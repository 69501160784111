import { Routes } from '@angular/router';

import { ACTIVITIES_ROUTES } from '../activities/ui/activities.routes';
import { PAGES_ROUTES } from '../pages/ui/pages.routes';
import { TEACHERS_ROUTES } from '../teachers/ui/teachers.routes';

export const CONTENT_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () => import('./content.component').then((c) => c.ContentComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'pages',
      },
      {
        path: 'pages',
        children: PAGES_ROUTES,
      },
      {
        path: 'teachers',
        children: TEACHERS_ROUTES,
      },
      {
        path: 'activities',
        children: ACTIVITIES_ROUTES,
      },
      {
        path: 'classes-planning',
        loadComponent: () => import('../planning/ui/planning.component').then((c) => c.PlanningComponent),
        data: {
          breadcrumbs: [{ label: 'Contenu', routerLink: '/admin/content' }, { label: 'Planning des cours' }],
        },
      },
    ],
  },
];
