import type { RoutesWithBreadcrumbs } from '../../app.routes';

export const STUDENTS_ROUTES: RoutesWithBreadcrumbs = [
  {
    path: '',
    loadComponent: () => import('./students.component').then((c) => c.StudentsComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
      {
        path: 'list',
        loadComponent: () => import('./students-list/students-list.component').then((c) => c.StudentsListComponent),
        data: {
          breadcrumbs: [{ label: 'Étudiants', routerLink: '/admin/students' }, { label: 'Liste des étudiants' }],
        },
      },
      {
        path: 'communications',
        loadComponent: () => import('./communications/communications.component').then((c) => c.CommunicationsComponent),
        data: {
          breadcrumbs: [{ label: 'Étudiants', routerLink: '/admin/students' }, { label: 'Communiquer aux étudiants' }],
        },
      },
      {
        path: 'add',
        loadComponent: () => import('./add-student/add-student.component').then((c) => c.AddStudentComponent),
        data: {
          breadcrumbs: [{ label: 'Étudiants', routerLink: '/admin/students/list' }, { label: 'Ajouter un étudiant' }],
        },
      },
      {
        path: 'edit/:studentId',
        loadComponent: () => import('./edit-student/edit-student.component').then((c) => c.EditStudentComponent),
        data: {
          breadcrumbs: [{ label: 'Étudiants', routerLink: '/admin/students/list' }, { label: 'Modifier un étudiant' }],
        },
      },
    ],
  },
];
