import type { Routes } from '@angular/router';

export const SETTINGS_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () => import('./settings.component').then((c) => c.SettingsComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'classes-locations',
      },
      {
        path: 'classes-locations',
        loadComponent: () => import('./classes-locations/ui/classes-locations.component').then((c) => c.ClassesLocationsComponent),
        data: {
          breadcrumbs: [{ label: 'Paramétrage', routerLink: '/admin/settings' }, { label: 'Salles de cours' }],
        },
      },
      {
        path: 'roles',
        loadComponent: () => import('./roles/ui/roles.component').then((c) => c.RolesComponent),
        data: {
          breadcrumbs: [{ label: 'Paramétrage', routerLink: '/admin/settings' }, { label: 'Rôles & permissions' }],
        },
      },
    ],
  },
];
