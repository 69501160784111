import type { RoutesWithBreadcrumbs } from '../../app.routes';

export const TEACHERS_ROUTES: RoutesWithBreadcrumbs = [
  {
    path: '',
    loadComponent: () => import('./teachers-list/teachers-list.component').then((c) => c.TeachersListComponent),
    data: {
      breadcrumbs: [{ label: 'Contenu', routerLink: '/admin/content' }, { label: 'Professeurs' }],
    },
  },
  {
    path: 'add',
    loadComponent: () => import('./add-teacher/add-teacher.component').then((c) => c.AddTeacherComponent),
    data: {
      breadcrumbs: [
        { label: 'Contenu', routerLink: '/admin/content' },
        { label: 'Professeurs', routerLink: '/admin/content/teachers' },
        { label: 'Ajouter un professeur' },
      ],
    },
  },
  {
    path: 'edit/:teacherId',
    loadComponent: () => import('./edit-teacher/edit-teacher.component').then((c) => c.EditTeacherComponent),
    data: {
      breadcrumbs: [
        { label: 'Contenu', routerLink: '/admin/content' },
        { label: 'Professeurs', routerLink: '/admin/content/teachers' },
        { label: 'Modifier un professeur' },
      ],
    },
  },
];
