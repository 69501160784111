import type { RoutesWithBreadcrumbs } from '../../app.routes';

export const SEMINARS_ROUTES: RoutesWithBreadcrumbs = [
  {
    path: '',
    loadComponent: () => import('./seminars.component').then((c) => c.SeminarsComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
      {
        path: 'list',
        loadComponent: () => import('./seminars-list/seminars-list.component').then((c) => c.SeminarsListComponent),
        data: {
          breadcrumbs: [{ label: 'Stages', routerLink: '/admin/seminars' }, { label: 'Tous les stages' }],
        },
      },
      {
        path: 'add',
        loadComponent: () => import('./add-seminar/add-seminar.component').then((c) => c.AddSeminarComponent),
        data: {
          breadcrumbs: [{ label: 'Stages', routerLink: '/admin/seminars' }, { label: 'Ajouter un nouveau stage' }],
        },
      },

      {
        path: ':seminarId',
        loadComponent: () => import('./seminar-details/seminar-details.component').then((c) => c.SeminarDetailsComponent),
        data: {
          breadcrumbs: [{ label: 'Stages', routerLink: '/admin/seminars' }, { label: 'Détail du stage' }],
        },
        children: [
          {
            path: 'informations',
            loadComponent: () => import('./edit-seminar/edit-seminar.component').then((c) => c.EditSeminarComponent),
            data: {
              breadcrumbs: [{ label: 'Stages', routerLink: '/admin/seminars' }, { label: 'Modifier les informations du stage' }],
            },
          },
          {
            path: 'attendees',
            loadComponent: () => import('./attendees/attendees.component').then((c) => c.AttendeesComponent),
            data: {
              breadcrumbs: [{ label: 'Stages', routerLink: '/admin/seminars' }, { label: 'Inscriptions' }],
            },
          },
          {
            path: 'communications',
            loadComponent: () => import('./communications/communications.component').then((c) => c.CommunicationsComponent),
            data: {
              breadcrumbs: [{ label: 'Stages', routerLink: '/admin/seminars' }, { label: 'Communiquer aux participants' }],
            },
          },
        ],
      },
    ],
  },
];
