import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { translationsFr } from './common/i18n/translations-fr';
import { PlacesGateway } from './places/places.gateway';

@Component({
  selector: 'epi-back-office-root',
  standalone: true,
  imports: [RouterModule, ToastModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  #geoSearchService = inject(PlacesGateway);

  constructor(private readonly config: PrimeNGConfig) {}

  ngOnInit() {
    this.config.setTranslation(translationsFr);
    this.#geoSearchService.generateSessionToken();
  }
}
