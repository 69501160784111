import { AuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import type { Route } from '@angular/router';
import type { MenuItem } from 'primeng/api';

import { ADMIN_ROUTES } from './admin/admin.routes';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToAdmin = () => redirectLoggedInTo(['admin']);

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'admin',
  },
  {
    path: 'forgot-password',
    loadComponent: () => import('./authentication/ui/forgot-password/forgot-password.component').then((c) => c.ForgotPasswordComponent),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToAdmin },
  },
  {
    path: 'login',
    loadComponent: () => import('./authentication/ui/login/login.component').then((c) => c.LoginComponent),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToAdmin },
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: ADMIN_ROUTES,
  },
];

export type RoutesWithBreadcrumbs = Array<
  Route & {
    data?: Route['data'] & { breadcrumbs: MenuItem[] };
  }
>;
