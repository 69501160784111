import type { Routes } from '@angular/router';

export const PAGES_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages-list/pages-list.component').then((c) => c.PagesListComponent),
    data: {
      breadcrumbs: [{ label: 'Contenu', routerLink: '/admin/content' }, { label: 'Pages' }],
    },
  },
  {
    path: 'add',
    loadComponent: () => import('./add-page/add-page.component').then((c) => c.AddPageComponent),
    data: {
      breadcrumbs: [
        { label: 'Contenu', routerLink: '/admin/content' },
        { label: 'Pages', routerLink: '/admin/content/pages' },
        { label: 'Ajouter une page' },
      ],
    },
  },
  {
    path: 'edit/:pageId',
    loadComponent: () => import('./edit-page/edit-page.component').then((c) => c.EditPageComponent),
    data: {
      breadcrumbs: [
        { label: 'Contenu', routerLink: '/admin/content' },
        { label: 'Pages', routerLink: '/admin/content/pages' },
        { label: 'Modifier une page' },
      ],
    },
  },
];
