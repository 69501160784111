import type { RoutesWithBreadcrumbs } from '../../app.routes';

export const ACTIVITIES_ROUTES: RoutesWithBreadcrumbs = [
  {
    path: '',
    loadComponent: () => import('./activities-list/activities-list.component').then((c) => c.ActivitiesListComponent),
    data: {
      breadcrumbs: [{ label: 'Contenu', routerLink: '/admin/content' }, { label: 'Disciplines' }],
    },
  },
  {
    path: 'add',
    loadComponent: () => import('./add-activity/add-activity.component').then((c) => c.AddActivityComponent),
    data: {
      breadcrumbs: [
        { label: 'Contenu', routerLink: '/admin/content' },
        { label: 'Disciplines', routerLink: '/admin/content/activities' },
        { label: 'Ajouter une discipline' },
      ],
    },
  },
  {
    path: 'edit/:activityId',
    loadComponent: () => import('./edit-activity/edit-activity.component').then((c) => c.EditActivityComponent),
    data: {
      breadcrumbs: [
        { label: 'Contenu', routerLink: '/admin/content' },
        { label: 'Disciplines', routerLink: '/admin/content/activities' },
        { label: 'Modifier une discipline' },
      ],
    },
  },
];
